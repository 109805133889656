import { SET_SUITABILITY_PROGRESS, SET_AI_GENERATING, SET_USER_GENERAL_INFO, SET_CURRENT_REPORT_UID, SET_CLIENT_USER_LIST } from "./actions";

const initialState = {
    "sectionNumber": 0,
    "subsectionNumber": 0,
    "questionsInsideSubsection": 0,
    "currentQuestionNumber": 0,
    "reportFinished": false,
    "currentQuestionNumberInSection": 0,
    "questionsInsideSection": 0
  };

const isAiAnswerGenerating = {
  "aiAnswering": false
};
const userGenralInfo = {
    "firstName": "",
    "lastName": "",
    "organisationName": "",
    "plan": {
      "planName": "",
      "trialRemainingDays": "",
      "trialRemainingHours": ""
    }
}
const currentReportUID = {
  "reportUID": '',
  "reportName": ''
}
const clientList = []
const SuitabilityProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUITABILITY_PROGRESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
const AiAnswerReducer = (state = isAiAnswerGenerating, action) => {
  switch(action.type){
    case SET_AI_GENERATING:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
const UserGeneralInfo = (state = userGenralInfo, action) => {
  switch(action.type){
    case SET_USER_GENERAL_INFO:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
const CurrentReportUid = (state = currentReportUID, action) => {
  switch(action.type){
    case SET_CURRENT_REPORT_UID:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
const CLientUserList = (state = clientList, action) => {
  switch(action.type){
    case SET_CLIENT_USER_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export { SuitabilityProgressReducer, AiAnswerReducer, UserGeneralInfo, CurrentReportUid, CLientUserList };