import { NavLink, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

// Custom components
// import { HSeparator } from 'components/separator/Separator';
import CenteredAuth from 'layouts/auth/types/Centered';

// Assets
// import { FcGoogle } from 'react-icons/fc';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
//Firebase
import { signUp, /*googleSignUp*/ } from 'firebaseFiles/FirebaseActions';
import {createUser} from '../../../services/apiServices'

function SignUp() {
  //navigate function
  const navigate = useNavigate();
  // Chakra color mode
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const textColorDetails = useColorModeValue('navy.700', 'secondaryGray.600');
  const textColorBrand = useColorModeValue('brand.500', 'white');
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  // const googleBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.200');
  // const googleText = useColorModeValue('navy.700', 'white');
  // const googleHover = useColorModeValue(
  //   { bg: 'gray.200' },
  //   { bg: 'whiteAlpha.300' },
  // );
  // const googleActive = useColorModeValue(
  //   { bg: 'secondaryGray.300' },
  //   { bg: 'whiteAlpha.200' },
  // );
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanytName] = useState('');
  const [email, setEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState();
  function handleFirstNameChange(event) {
    setFirstName(event.target.value);
  }
  function handleLastNameChange(event) {
    setLastName(event.target.value);
  }
  function handleCompanytNameChange(event) {
    setCompanytName(event.target.value);
  }
  // Email validation function
  const validateEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    // Simple regex for email validation
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email.toLowerCase())) {
      setError('');
    } else {
      setError('Please enter a valid email address.');
    }
  };
  // Password validation function
  const validateUserPassword = (e) => {
    const userPass = e.target.value;
    setUserPassword(userPass);
    // Simple regex for Password validation
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^#~+±*=£€°§;:_¨\-\\\/%`.,<>(){}[\]])[A-Za-z\d@$!%*?&^#~+±*=£€°§;:_¨\-\\\/%`.,<>(){}[\]]{8,}$/;
    if (regex.test(userPass)) {
      setPasswordError('');
    } else {
      setPasswordError(' Password must contain a lowercase character, uppercase charecter, number, special charecter "@$!%*?&^#~+±*=£€°§;:_¨-\/%`.,<>(){}[]" and 8 characters of length.');
    }
  };
  const createAccount = async () => {
    if (!error && !passwordError && firstName && lastName && companyName) {
      let resPayload = {
        firstName: firstName,
        lastName: lastName,
        mail: email,
        organisationName: companyName,
      };
      const response = await signUp(
        email,
        userPassword,
        firstName,
        lastName,
        companyName,
      );
      setTimeout(async () => {
        if (response === 'User signed Up') {
          await createUser(resPayload)
          navigate('/suitability-report', { replace: true });
        } else if (response === 'email-already-in-use') {
          setError('Email address already in use.');
        }
      },1000)
    }
  };
  // const userGoogleSignIn = async () => {
  //   const authRepsonse = await googleSignUp();
  //   console.log('authRepsonse', authRepsonse);
  //   if (authRepsonse === 'UserSignedIn') {
  //     navigate('/suitability-report', { replace: true });
  //   }
  // };
  // useEffect(() =>{
  //   // navigate('/auth/sign-in');
  // },[])
  return (
    <CenteredAuth
      image={'linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'}
      cardTop={{ base: '40px', md: '6vh' }}
      cardBottom={{ base: '50px', lg: '100px' }}
    >
      <Flex
        maxW="max-content"
        mx={{ base: 'auto', lg: '0px' }}
        me="auto"
        justifyContent="center"
        px={{ base: '20px', md: '0px' }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading
            color={textColor}
            fontSize={{ base: '34px', lg: '36px' }}
            mb="10px"
          >
            Sign Up
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign up!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: '100%', md: '420px' }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: 'auto', lg: 'unset' }}
          me="auto"
          mb={{ base: '20px', md: 'auto' }}
        >
          {/* <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
            onClick={() => userGoogleSignIn()}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign up with Google
          </Button> */}
          {/* <Flex align="center" mb="25px">
            <HSeparator />
            <Text color={textColorSecondary} mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex> */}
          <FormControl>
            <SimpleGrid
              columns={{ base: '1', md: '2' }}
              gap={{ sm: '10px', md: '26px' }}
            >
              <Flex direction="column">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  First name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isrequired="true"
                  fontSize="sm"
                  ms={{ base: '0px', md: '4px' }}
                  placeholder="First name"
                  variant="auth"
                  mb="8px"
                  size="lg"
                  id="signUpFirstName"
                  onChange={handleFirstNameChange}
                />
              </Flex>
              <Flex direction="column">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Last name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isrequired="true"
                  variant="auth"
                  fontSize="sm"
                  placeholder="Last name"
                  mb="8px"
                  size="lg"
                  id="signUpLastName"
                  onChange={handleLastNameChange}
                />
              </Flex>
            </SimpleGrid>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Company<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isrequired="true"
              variant="auth"
              fontSize="sm"
              type="email"
              placeholder="Avenir"
              mb="8px"
              size="lg"
              value={companyName}
              onChange={handleCompanytNameChange}
              id="signUpCompanyName"
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isrequired="true"
              variant="auth"
              fontSize="sm"
              type="email"
              placeholder="mail@avenir.com"
              mb="8px"
              size="lg"
              value={email}
              onChange={validateEmail}
              id="signUpMail"
            />
            {error && (
              <Text status="error" color="red" mb="20px" fontSize="10px">
                *{error}
              </Text>
            )}
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              isrequired="true"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isrequired="true"
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '4px' }}
                placeholder="Min. 8 characters"
                mb="8px"
                size="lg"
                type={show ? 'text' : 'password'}
                id="signUpPassword"
                value={userPassword}
                onChange={validateUserPassword}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: 'pointer' }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {passwordError && (
              <Text status="error" color="red" mb="20px" fontSize="10px">
                *{passwordError}
              </Text>
            )}
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="start">
                <Checkbox
                  id="remember-login"
                  colorScheme="brand"
                  me="10px"
                  mt="3px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  By creating an account means you agree to the{' '}
                  <Link
                    href="https://cdn.prod.website-files.com/661e965fa0ea3712f7d8aea3/66a124379868ae3c947e5318_Avenir%20Terms%20July%2023%202024.pdf"
                    fontWeight="500"
                    color="blue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </Link>{' '}
                  {' '}and our{' '}
                  <Link
                    href="https://cdn.prod.website-files.com/661e965fa0ea3712f7d8aea3/662bce541fdb810bdbaeda19_Avenir%20Website_Privacy%20Policy.pdf"
                    fontWeight="500"
                    color="blue"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>
                </FormLabel>
              </FormControl>
            </Flex>
            <Button
              variant="brand"
              fontSize="14px"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              bg="avenirSuitabilityColor.600"
              onClick={() => createAccount()}
            >
              Create my account
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="sm">
              Already a member?
              <NavLink to="/auth/sign-in">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Sign in
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </CenteredAuth>
  );
}

export default SignUp;
