import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Flex,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  Input,
  Grid,
  HStack,
  Icon,
} from '@chakra-ui/react';
import { LuPlusCircle } from 'react-icons/lu';
// Custom components
import Card from 'components/card/Card';
//import files
import V1PreviewTemplate from '../components/V1PreviewTemplate';
import V1CreateNewClient from '../components/V1CreateNewClient';
import { AiOutlineExpand } from 'react-icons/ai';
import { MdOutlineSwitchLeft } from 'react-icons/md';
//Import Api's
import { createClient } from '../../../services/apiServices';

const V1NewReport = ({ AsssignNewReportDetails, UpdateNewReportState }) => {
  const userInfo = useSelector((state) => state.usrinf);
  const reduxClientLists = Object.values(useSelector((state) => state.clientList))
  const [showPreview, setShowPreview] = useState(false);
  const [clientName, setClientName] = useState('');
  const [showCreateClientModal, setShowCreateClientModal] = useState(false);
  const [reportName, setReportName] = useState('');
  const [clientList] = useState(reduxClientLists);
  const [showClientList, setShowClientList] = useState(false);
  const [clientId, setClientId] = useState('');
  const [previewType, setPreviewType] = useState();
  const [selecetedType, setSelectedType] = useState('');
  const [filteredCLientlist, setFilteredCLientlist] = useState([]);
  const [errorMessage, serErrorMessage] = useState('');
  const handlePreviewClick = (val) => {
    setPreviewType(val);
    setShowPreview(true);
    serErrorMessage('')
  };
  const selectCard = (val) => {
    setSelectedType(val);
    setShowPreview(false);
    serErrorMessage('')
  };
  const handleClientNameChange = (e) => {
    let searchResult = e.target.value;
    setClientName(e.target.value);
    setShowClientList(true);
    if (!e.target.value) {
      setFilteredCLientlist(clientList.slice(0, 6));
      setClientId('');
    } else {
      const filteredfiveClients = clientList
        .filter((client) =>
          client.firstName.toLowerCase().includes(searchResult.toLowerCase()),
        )
        .sort((a, b) => {
          if (a.firstName.toLowerCase() === searchResult.toLowerCase())
            return -1;
          if (b.firstName.toLowerCase() === searchResult.toLowerCase())
            return 1;
          return 0;
        })
        .slice(0, 6); //
      setFilteredCLientlist(filteredfiveClients);
    }
  };
  const handleClientNameBlur = () => {
    setShowClientList(false);
  };
  const handleClose = () => {
    setShowPreview(false);
  };
  const handleReportNameChange = (e) => {
    setReportName(e.target.value);
    serErrorMessage('')
  };
  const updateReportState = () => {
    if (selecetedType.length && reportName.length && clientId) {
      let res = {
        clientUID: clientId,
        templateUID: selecetedType === 'switch' ? '733' : '734',
        reportName: reportName,
      };
      AsssignNewReportDetails(res);
      UpdateNewReportState(2);
    } else {
      if(!selecetedType.length){
        serErrorMessage('Please select a template.')
      } else if(!clientId){
        serErrorMessage('Please select a client for this report.')
      } else if (!reportName.length){
        serErrorMessage('Please enter a name for this report.')
      }
    }
  };
  const addNewCleint = () => {
    setShowCreateClientModal(true);
  };
  const closeClient = () => {
    setShowCreateClientModal(false);
  };
  const createNewClient = async (res) => {
    const uid = await createClient(res);
    setClientId(uid.clientUID);
    const fullName = `${res.firstName} ${res.lastName}`;
    setClientName(fullName);
    setShowCreateClientModal(false);
    setShowClientList(false);
    serErrorMessage('')
  };
  const selectedClient = (client) => {
    const fullName = `${client.firstName} ${client.lastName}`;
    setClientName(fullName);
    setClientId(client.clientUID);
    setShowClientList(false);
    serErrorMessage('')
  };
  return (
    <Box>
      <Box w="80vw" p="36px">
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          color="#707EAE"
          fontWeight="500"
          fontSize="14px"
          lineHeight="24px"
        >
          {userInfo.organisationName} / New Report
        </Text>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="34px"
          color="#2B3674"
          mb="5px"
          lineHeight="42px"
        >
          New Report
        </Text>
        <Flex direction="column" align="center" p="24px" position="relative">
          <Box
            h="30vh"
            bgGradient="linear(to-b, avenirSuitabilityColor.600, avenirSuitabilityColor.600)"
            position="absolute"
            w="100%"
            borderRadius="20px"
          />
          <Tabs
            variant="unstyled"
            mt={{ base: '60px', md: '50px' }}
            zIndex="0"
            display="flex"
            flexDirection="column"
          >
            <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
              <TabPanel
                w={{ sm: '330px', md: '700px', lg: '850px' }}
                p="0px"
                mx="auto"
              >
                <Card p="30px">
                  <Box>
                    <VStack align="flex-start" spacing={4}>
                      <Text
                        sx={{ textStyle: 'manropeHeading' }}
                        fontSize="24px"
                        fontWeight="700"
                        letterSpacing="-0.48px"
                        color="#2B3674"
                      >
                       Set Up Your new report
                      </Text>
                      <Flex w="100%" justifyContent="space-between">
                        <Box w="48%">
                          <Text
                            sx={{ textStyle: 'dmsansNormal' }}
                            fontWeight="500"
                            fontSize="14px"
                            color="#1B2559"
                            letterSpacing="-0.28px"
                            lineHeight="28px"
                          >
                            Report Name
                          </Text>
                          <Input
                            value={reportName}
                            placeholder="Suitability 02-04-2024"
                            borderRadius="8px"
                            mt="8px"
                            borderColor={'#E6E6E6'}
                            onChange={handleReportNameChange}
                          />
                        </Box>
                        <Box
                          w="48%"
                          onBlur={() => setTimeout(handleClientNameBlur, 300)}
                        >
                          <Text
                            sx={{ textStyle: 'dmsansNormal' }}
                            fontWeight="500"
                            fontSize="14px"
                            color="#1B2559"
                            letterSpacing="-0.28px"
                            lineHeight="28px"
                          >
                            Which client is it for?
                          </Text>
                          <Input
                            value={clientName}
                            placeholder="Add a new client"
                            borderRadius="8px"
                            mt="8px"
                            borderColor={'#E6E6E6'}
                            onChange={handleClientNameChange}
                            onFocus={handleClientNameChange}
                          />
                          {showClientList ? (
                            <Box
                              p={4}
                              mt="4px"
                              boxShadow="md"
                              bg="white"
                              w="300px"
                              borderRadius="16px"
                              position="absolute"
                              zIndex="123"
                            >
                              <VStack align="start" spacing={4}>
                                <HStack
                                  spacing={2}
                                  onClick={addNewCleint}
                                  cursor="pointer"
                                >
                                  <Icon
                                    as={LuPlusCircle}
                                    boxSize="17px"
                                    color="#1B2559"
                                  />
                                  <Text
                                    color="#1B2559"
                                    sx={{ textStyle: 'dmsansNormal' }}
                                    fontWeight="400"
                                    fontSize="14px"
                                  >
                                    Add a new client
                                  </Text>
                                </HStack>
                                {filteredCLientlist.length > 0 ? (
                                  <Flex flexDirection="column" gap="10px">
                                    {filteredCLientlist.map((client, index) => (
                                      <Text
                                        key={client.clientUID} // Use clientUID as a unique key
                                        cursor="pointer"
                                        color="#1B2559"
                                        sx={{ textStyle: 'dmsansNormal' }}
                                        fontWeight="400"
                                        fontSize="14px"
                                        onClick={() => selectedClient(client)}
                                      >
                                        {client.firstName} {client.lastName}
                                      </Text>
                                    ))}
                                  </Flex>
                                ) : null}
                              </VStack>
                            </Box>
                          ) : null}
                        </Box>
                      </Flex>
                      <Box w="100%" mt="10px">
                        <Text
                          sx={{ textStyle: 'dmsansNormal' }}
                          fontWeight="500"
                          fontSize="14px"
                          color="#1B2559"
                          letterSpacing="-0.28px"
                          lineHeight="28px"
                          mb="8px"
                        >
                          Select your template
                        </Text>
                        <Text
                          sx={{ textStyle: 'dmsansNormal' }}
                          fontWeight="400"
                          fontSize="14px"
                          color="avenirGrey.500"
                          lineHeight="20px"
                          letterSpacing="-0.28px"
                        >
                          Choose a template to define the report’s structure, required files, and content generated by Avenir.
                        </Text>
                      </Box>
                      <Grid templateColumns="repeat(3, 1fr)" gap={6} w="100%">
                        <Card
                          p="18px 14px"
                          borderRadius="12px"
                          border="1px solid #E2E8F0"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          w="280px"
                          h="180px"
                          borderColor={
                            selecetedType === 'switch'
                              ? 'avenirSuitabilityColor.600'
                              : '#F9F9F9'
                          }
                          _hover={{
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                          }}
                        >
                          <Box
                            onClick={() => selectCard('switch')}
                            cursor="pointer"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Flex
                              h="31px"
                              w="31px"
                              mb="10px"
                              backgroundColor="#F6F8FD"
                              borderRadius="50%"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <MdOutlineSwitchLeft
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  color: '#4E13C3',
                                }}
                              />
                            </Flex>
                            <Flex
                              justify="space-between"
                              align="center"
                              mb="12px"
                            >
                              <Text
                                sx={{ textStyle: 'dmsansText' }}
                                fontWeight="700"
                                fontSize="18px"
                                color="#1B2559"
                              >
                                Pension Switch
                              </Text>
                            </Flex>
                            <Text
                              sx={{ textStyle: 'dmsansNormal' }}
                              fontWeight="400"
                              fontSize="14px"
                              color="#A3AED0"
                              letterSpacing="-0.28px"
                            >
                              Transfer a client's pension to a different pension plan.
                            </Text>
                          </Box>
                          <Flex alignItems="center" justifyContent="flex-end">
                            <Button
                              variant="link"
                              color="avenirSuitabilityColor.600"
                              sx={{ textStyle: 'dmsansText' }}
                              mt="12px"
                              fontWeight="500"
                              fontSize="14px"
                              textDecoration="underline"
                              onClick={() => handlePreviewClick('switch')}
                            >
                              Preview Template
                            </Button>
                          </Flex>
                        </Card>
                        <Card
                          p="18px 14px"
                          borderRadius="12px"
                          border="1px solid #E2E8F0"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          w="280px"
                          h="180px"
                          borderColor={
                            selecetedType === 'consolidation'
                              ? 'avenirSuitabilityColor.600'
                              : '#F9F9F9'
                          }
                          _hover={{
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                          }}
                        >
                          <Box
                            onClick={() => selectCard('consolidation')}
                            cursor="pointer"
                          >
                            <Flex
                              h="31px"
                              w="31px"
                              mb="10px"
                              backgroundColor="#F6F8FD"
                              borderRadius="50%"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <AiOutlineExpand
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  color: '#4E13C3',
                                }}
                              />
                            </Flex>
                            <Flex
                              justify="space-between"
                              align="center"
                              mb="12px"
                            >
                              <Text
                                sx={{ textStyle: 'dmsansText' }}
                                fontWeight="700"
                                fontSize="18px"
                                color="#1B2559"
                              >
                                Pension Consolidation
                              </Text>
                            </Flex>
                            <Text
                              sx={{ textStyle: 'dmsansNormal' }}
                              fontWeight="400"
                              fontSize="14px"
                              color="#A3AED0"
                              letterSpacing="-0.28px"
                            >
                             Combine multiple clients’ pensions into a single plan.
                            </Text>
                          </Box>
                          <Flex alignItems="center" justifyContent="flex-end">
                            <Button
                              variant="link"
                              color="avenirSuitabilityColor.600"
                              sx={{ textStyle: 'dmsansText' }}
                              mt="12px"
                              fontWeight="500"
                              fontSize="14px"
                              textDecoration="underline"
                              onClick={() =>
                                handlePreviewClick('consolidation')
                              }
                            >
                              Preview Template
                            </Button>
                          </Flex>
                        </Card>
                      </Grid>
                      {errorMessage.length ? (
                        <Flex w="100%" justifyContent="flex-end" mt="2px" sx={{ textStyle: 'manropeHeading' }} color="#B20D30" fontSize="12px" fontWeight="600">
                          {errorMessage}
                        </Flex>
                      ) : (
                        <Flex
                          w="100%"
                          justifyContent="flex-end"
                          mt="14px"
                        ></Flex>
                      )}
                      <Flex w="100%" justifyContent="flex-end" mt="-14px">
                        <Button
                          color="white"
                          bg="avenirSuitabilityColor.600"
                          p="11px 22px"
                          borderRadius="12px"
                          fontSize="14px"
                          sx={{ textStyle: 'dmsansNormal' }}
                          _hover={{ bg: 'avenirSuitabilityColor.600' }}
                          onClick={updateReportState}
                        >
                          Start Report
                        </Button>
                      </Flex>
                    </VStack>
                  </Box>
                </Card>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Box>
      {showPreview ? (
        <V1PreviewTemplate
          previewType={previewType}
          selectCard={selectCard}
          handleClose={handleClose}
        ></V1PreviewTemplate>
      ) : null}
      {showCreateClientModal ? (
        <V1CreateNewClient
          closeClient={closeClient}
          createNewClient={createNewClient}
        ></V1CreateNewClient>
      ) : null}
    </Box>
  );
};

export default V1NewReport;
