import React, { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  InputGroup,
  InputLeftAddon,
  Input,
  Alert,
  AlertIcon 
} from '@chakra-ui/react';

import SkipValidateAnswer from './SkipValidateAnswer';

export default function Default({ question, userAnswered }) {
  const [textError, setTextError] = useState(false);
  const [poundError, setPoundError] = useState(false);
  const [showErrorState, setShowErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [text, setText] = useState(
    question.Answers &&
      question.Answers.length > 0 &&
      question.Answers[1].aiAnswer
      ? question.Answers[1].aiAnswer
      : '',
  );
  const [poundValue, setPoundValue] = useState(
    question.Answers &&
      question.Answers.length > 1 &&
      question.Answers[0].aiAnswer
      ? question.Answers[0].aiAnswer
      : '',
  );
  const handleTextValue = (val, regex) => {
    setShowErrorState(false)
    const regex_t = new RegExp(regex);
    if (!poundValue.length) {
      if (!regex_t.test(val) || val > 100 || val < 0) {
        setTextError(true);
      } else {
        setTextError(false);
      }
      setText(val);
    }
    if (val.length === 0) {
      setTextError(false);
    }
  };
  const handlePoundValue = (val, regex) => {
    setShowErrorState(false)
    const regex_t = new RegExp(regex);
    if (!text.length) {
      if (!regex_t.test(val)) {
        setPoundError(true);
      } else {
        setPoundError(false);
      }
      setPoundValue(val);
    }
    if (val.length === 0) {
      setPoundError(false);
    }
  };
  const vallidateAnswer = () => {
    let textVar = text ? text : '-1';
    let poundVarVar = poundValue ? poundValue : '-1';
    let userans = {
      '%': textVar,
      '£': poundVarVar,
    };
    if (!textError && !poundError && (text.length || poundValue.length)) {
      userAnswered(userans);
    } else {
      setShowErrorState(true)
      if( !text.length || !poundValue.length){
        setErrorMessage(' Please answer the required fields') 
        if(!textError || !poundError){
          setErrorMessage('Check format of your answer')
        }
      }
    }
  };
  const skipQuestion = () => {
    console.log('skipQuestion');
  };
  return (
    <Flex
      h="100%"
      overflow="hidden"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Text sx={{ textStyle: 'manropeHeading' }} fontSize="18px">
            {question.Text}
          </Text>
        </Flex>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          color="avenirGrey.400"
          lineHeight="16px"
          fontWeight="400"
        >
          {question.SubText}
        </Text>
        <Flex
          mt="40px"
          w="65%"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {question.Answers[0] ? (
            <InputGroup sx={{ borderColor: 'avenirGrey.200' }}>
              <InputLeftAddon
                sx={{ backgroundColor: 'avenirGrey.100', fontWeight: '600' }}
                children="£"
              />
              <Input
                placeholder={question.Answers[0].placeholder}
                value={poundValue}
                onChange={(e) =>
                  handlePoundValue(e.target.value, question.Answers[0].regex)
                }
                borderColor={poundError ? '#E4103F' : '#E6E6E6'}
              />
            </InputGroup>
          ) : null}
          {question.Answers[1] ? (
            <Text
              mt="12px"
              mb="12px"
              fontWeight="700"
              sx={{ textStyle: 'manropeHeading' }}
            >
              {' '}
              OR{' '}
            </Text>
          ) : null}
          {question.Answers[1] ? (
            <InputGroup sx={{ borderColor: 'avenirGrey.200' }}>
              <InputLeftAddon
                sx={{ backgroundColor: 'avenirGrey.100', fontWeight: '600' }}
                children="%"
              />
              <Input
                placeholder={question.Answers[1].placeholder}
                value={text}
                onChange={(e) =>
                  handleTextValue(e.target.value, question.Answers[1].regex)
                }
                borderColor={textError ? '#E4103F' : '#E6E6E6'}
              />
            </InputGroup>
          ) : null}
        </Flex>
      </Box>
      <Flex flexDirection="row-reverse">
        {showErrorState ? (
          <Alert
            status="error"
            bg="#FED7D7"
            borderRadius="10px"
            sx={{ textStyle: 'manropeHeading' }}
            fontWeight="500"
          >
            <AlertIcon color="#E53E3E" />
            {errorMessage}
          </Alert>
        ) : (
          <SkipValidateAnswer
            skipQuestion={skipQuestion}
            vallidateAnswer={vallidateAnswer}
          />
        )}
      </Flex>
    </Flex>
  );
}
