// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAtpvknvn9HvPdkYp8tlPhkferWfop1x_E",
  authDomain: "directindexing-414110.firebaseapp.com",
  projectId: "directindexing-414110",
  storageBucket: "directindexing-414110.appspot.com",
  messagingSenderId: "287675149625",
  appId: "1:287675149625:web:d959619bb719e0afcd0ce8",
  measurementId: "G-389YTLT9NC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseDB = getFirestore(app);
const firebaseAuth = getAuth(app);

export {app, firebaseDB, firebaseAuth };