import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthLayout from './layouts/auth';
import { SuitabilityReport } from 'layouts/suitability-report';
import V1Payments from '../src/views/suitabilityReportViews/V1report/V1Payments.js' 
import PageNotFound from  '../src/views/admin/main/others/404'
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useEffect, useState } from 'react';
// Chakra imports
//firebase auth
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useLocation } from 'react-router-dom';
//import api calls 
import {getClientDetails} from '../src/services/apiServices'
import { setUserGeneralInfo } from 'reducers/actions';
// Session Time Out component
import SessionTimeout from '../src/views/suitabilityReportViews/components/SessionTimeout'

export default function Main() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsUserLoggedIn(true);
        setLoading(false);
      } else {
        setIsUserLoggedIn(false);
        setLoading(false);
      }
    });
    return () => unsubscribe(); // Cleanup the subscription
  }, [location.pathname]); 
  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const res = await getClientDetails();
        dispatch(setUserGeneralInfo(res));
      } catch (error) {
        console.error('Failed to fetch client details:', error);
      }
    };

    if (isUserLoggedIn) {
      fetchClientDetails();
    }
  }, [isUserLoggedIn, dispatch]);
  return (
    <ChakraProvider theme={currentTheme}>
      <SessionTimeout />
      {!loading ? (
        <Routes>
          <Route
            path="/"
            element={
              isUserLoggedIn ? (
                <Navigate to="/suitability-report/my-reports" replace />
              ) : (
                <Navigate to="/auth/sign-in" replace />
              )
            }
          />
          <Route path="auth/*" element={<AuthLayout />} />
          <Route path="subscribe/*" element={<V1Payments />} />
          <Route path="error/*" element={<PageNotFound />} />
          <Route
            path="suitability-report/*"
            element={
              isUserLoggedIn ? (
                <SuitabilityReport
                  theme={currentTheme}
                  setTheme={setCurrentTheme}
                />
              ) : (
                <Navigate to="/auth/sign-in" replace />
              )
            }
          />
        </Routes>
      ) : null}
    </ChakraProvider>
  );
}
