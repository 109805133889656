import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
// Custom components
import Card from 'components/card/Card';
import React, { useState } from 'react';
import V1UploadPdf from './V1UploadPdf';
import { postCreateContext } from 'services/apiServices';
const UploadComponent = ({ sectionUploadChange, uploadPdfsectiondetails }) => {
  const currentReportUID = useSelector((state) => state.reportUID);
  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
    pricing: false,
  });
  const [activeTab, setActiveTab] = useState(0); // Add state for active tab
  const [uploadedRequiredfields, setUploadedRequiredfields] = useState(false);
  const currentUpload = React.useRef();
  const recommend = React.useRef();
  const [showCreateingReport, setShowCreateingReport] = useState(false);
  const [showLoadingInNext, setShowLoadingInNext] = useState(false);
  const uploadSectionDetails = uploadPdfsectiondetails
    ? uploadPdfsectiondetails
    : [];
  const updatePdfState = (val) => {
    if (val) {
      setUploadedRequiredfields(true);
    }
  };
  const setLoadingInNextButton = (val) => {
    setShowLoadingInNext(val);
  };
  const submitPdf = async () => {
    const res = {
      reportUID: currentReportUID.reportUID,
    };
    setShowCreateingReport(true);
    await postCreateContext(res);
    setShowCreateingReport(false);
    sectionUploadChange(1);
  };
  const handleTabChange = (index) => {
    if (uploadedRequiredfields) {
      setActiveTab(index);
      if (index === 0) {
        setActiveBullets({ product: true, pricing: false });
      } else if (index === 1) {
        setActiveBullets({ product: true, pricing: true });
      }
    }
  };

  return (
    <Box w="100%" p="36px" h="100%" overflow="auto">
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        color="#707EAE"
        fontWeight="500"
        fontSize="14px"
        lineHeight="24px"
      >
        Dashboard / New Report
      </Text>
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="34px"
        color="#2B3674"
        mb="5px"
        lineHeight="42px"
      >
        Client Information
      </Text>
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        p="24px"
        position="relative"
      >
        <Box
          h="30vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />

        <Tabs
          variant="unstyled"
          mt={{ base: '60px', md: '50px' }}
          zIndex="0"
          display="flex"
          flexDirection="column"
          index={activeTab} // Control active tab
          onChange={() => {}} // Prevent changing tab on header click
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={currentUpload}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.pricing ? 'white' : 'brand.400',
                  left: {
                    sm: '12px',
                    md: uploadSectionDetails[0].category.length * 4,
                  },
                  top: {
                    sm: activeBullets.product ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.product ? '40px' : '38px',

                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.product ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.product ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.product ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  {uploadSectionDetails[0].category}
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={recommend}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.pricing ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.pricing ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.pricing ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  {uploadSectionDetails[1].category}
                </Text>
              </Flex>
            </Tab>
          </TabList>
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text sx={{ textStyle: 'manropeHeading' }} fontSize="24px">
                  {uploadSectionDetails[0].title}
                </Text>
                <Text
                  sx={{ textStyle: 'manropeHeading' }}
                  fontSize="14px"
                  color="avenirGrey.400"
                  lineHeight="16px"
                  fontWeight="400"
                  mb="24px"
                  mt="8px"
                >
                  {uploadSectionDetails[0].description}
                </Text>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
                  {uploadSectionDetails[0].documents.map((secName, index) => (
                    <V1UploadPdf
                      key={index}
                      secName={secName}
                      updatePdfState={updatePdfState}
                      setLoadingInNextButton={setLoadingInNextButton}
                    />
                  ))}
                </SimpleGrid>
                <Flex direction="column" w="100%">
                  <Flex justify="space-between" mt="24px">
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      bg="avenirSuitabilityColor.600"
                      onClick={() => handleTabChange(1)} // Change tab on Next button click
                    >
                      {showLoadingInNext ? (
                        <Spinner
                          thickness="2px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="avenirSuitabilityColor.800"
                          width="15px" // Set custom width
                          height="15px" // Set custom height
                        />
                      ) : (
                        'Next'
                      )}
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text sx={{ textStyle: 'manropeHeading' }} fontSize="24px">
                  {uploadSectionDetails[1].title}
                </Text>
                <Text
                  sx={{ textStyle: 'manropeHeading' }}
                  fontSize="14px"
                  color="avenirGrey.400"
                  lineHeight="16px"
                  fontWeight="400"
                  mb="24px"
                  mt="8px"
                >
                  {uploadSectionDetails[1].description}
                </Text>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
                  {uploadSectionDetails[1].documents.map((secName, index) => (
                    <V1UploadPdf
                      key={index}
                      secName={secName}
                      updatePdfState={updatePdfState}
                      setLoadingInNextButton={setLoadingInNextButton}
                    ></V1UploadPdf>
                  ))}
                </SimpleGrid>
                <Flex direction="column" w="100%">
                  <Flex justify="space-between" mt="24px">
                    <Button
                      variant="light"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      onClick={() => handleTabChange(0)} // Change tab on Prev button click
                    >
                      Go Back
                    </Button>
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      bg="avenirSuitabilityColor.600"
                      onClick={submitPdf}
                    >
                      {showLoadingInNext ? (
                        <Spinner
                          thickness="2px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="avenirSuitabilityColor.800"
                          width="15px" // Set custom width
                          height="15px" // Set custom height
                        />
                      ) : (
                        'Start Report'
                      )}
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <Modal isOpen={showCreateingReport}>
        <ModalOverlay />
        <ModalContent style={{ marginTop: '35vh' }}>
          <ModalBody>
            <Flex
              h="17vh"
              borderRadius="16px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Spinner
                thickness="8px"
                speed="0.65s"
                emptyColor="gray.200"
                color="avenirSuitabilityColor.800"
                width="55px" // Set custom width
                height="55px" // Set custom height
              />
              <Text
                sx={{ textStyle: 'manropeHeading' }}
                fontSize="20px"
                lineHeight="30px"
                fontWeight="500"
                letterSpacing="-0.4px"
                mt="20px"
                color="black"
              >
                Extracting data from your files
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default UploadComponent;
