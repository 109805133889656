import React from 'react';
import { Box, Flex, Text, Button } from '@chakra-ui/react';
import { AiOutlineDownload } from 'react-icons/ai';

export default function Default({ question, userAnswered, generatePdfOrDocQuestion }) {
  const clickedGeneratePdfOrDOc = (value) => {
    generatePdfOrDocQuestion(value);
  };
  return (
    <Flex
      h="100%"
      overflow="hidden"
      flexDirection="column"
      w="100%"
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Text sx={{ textStyle: 'manropeHeading' }} fontSize="18px">
            {question.Text}
          </Text>
        </Flex>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          color="avenirGrey.400"
          lineHeight="16px"
          fontWeight="400"
          mt="8px"
        >
          {question.SubText}
        </Text>
      </Box>
      <Flex gap="16px" mt="30px">
        <Button
          leftIcon={<AiOutlineDownload style={{ fontSize: '24px' }} />}
          colorScheme="avenirSuitabilityColor"
          bg="avenirSuitabilityColor.800"
          variant="solid"
          p="12px 18px"
          borderRadius="10px"
          h="46px"
          _hover={{
            bg: 'avenirSuitabilityColor.800',
          }}
          onClick={() => clickedGeneratePdfOrDOc('PDF')}
        >
          PDF
        </Button>
        <Button
          leftIcon={<AiOutlineDownload style={{ fontSize: '24px' }} />}
          colorScheme="avenirSuitabilityColor"
          bg="avenirSuitabilityColor.800"
          variant="solid"
          p="12px 18px"
          borderRadius="10px"
          h="46px"
          _hover={{
            bg: 'avenirSuitabilityColor.800',
          }}
          onClick={() => clickedGeneratePdfOrDOc('DOC')}
        >
          DOC
        </Button>
      </Flex>
    </Flex>
  );
}
