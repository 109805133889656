// Import the necessary Firebase modules
import { firebaseDB, firebaseAuth } from './firebase-config';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

// Function to handle user registration
const signUp = async (email, password, firstName,lastName, companyName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);
    const user = userCredential.user;
    // Add a new document in collection "users" with user's UID
    await setDoc(doc(firebaseDB, "SuitabilityUsers", user.uid), {
      firstName: firstName,
      lastName: lastName,
      companyName: companyName,
      mailId: email
    });
    return("User signed Up")
  } catch (error) {
    console.error("Error in sign-up:", error.message);
    return ('email-already-in-use')
  }
};

// Function to handle user login
const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(firebaseAuth, email, password);
    console.log("User signed in");
    return(userCredential)
  } catch (error) {
    console.error("Error in sign-in:", error.message);
    return('auth/invalid-credential')
  }
};

const googleSignUp = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(firebaseAuth, provider);
    // This gives you a Google Access Token. You can use it to access the Google API.
    // const credential = GoogleAuthProvider.credentialFromResult(result);
    // const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    await setDoc(doc(firebaseDB, "SuitabilityUsers", user.uid), {
      firstName: user.displayName,
      lastName: '',
      companyName: '',
      mailId: user.email
    });
    console.log("User signed in:");
    return('UserSignedIn')
  } catch (error) {
    console.error(error);
    return('error')
  }
};

// Export both functions for use in other parts of the application
export { signUp, signIn, googleSignUp };
