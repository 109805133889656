import {
  Flex,
  Button,
  Link,
  Text,
  useColorModeValue,
  Box,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {getResetReport} from  '../../../../../services/apiServices'

// Assets

import React from 'react';

function Alerts() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  // const brandColor = useColorModeValue('brand.500', 'brand.400');
  const navigate = useNavigate();
    const resetReport = async () => {
        try {
            await getResetReport();
            navigate('/');
        } catch (error) {
            console.error('Error resetting the report:', error);
        }
    };
  return (
    <Box>
      <Flex
        direction="column"
        align="center"
        pt={{ sm: '125px', lg: '75px' }}
        mt="250px"
      >
        <Text
          color={textColor}
          fontSize={{ base: '40px', lg: '46px' }}
          fontWeight="700"
          mb="30px"
          textAlign={{ base: 'center', md: 'start' }}
        >
          Ah, dang. Something went wrong.
        </Text>
        <Flex align="center" direction={{ base: 'column', md: 'row' }}>
          <Flex>
          <Link
              href="/"
            >
              <Button
              variant="light"
              fontSize="sm"
              borderRadius="12px"
              p="8px 18px"
              marginRight="10px"
              bg="avenirSuitabilityColor.600"
              color="avenirWhite"
              _hover={{ bg: 'avenirSuitabilityColor.600' }}
              border="1px"
              borderColor="avenirSuitabilityColor.600"
              onClick={resetReport}
            >
              Refresh
            </Button>
            </Link>
          {/* <Button
              variant="light"
              fontSize="sm"
              borderRadius="12px"
              p="8px 18px"
              marginRight="10px"
              bg="avenirWhite"
              color="avenirSuitabilityColor.600"
              _hover={{ bg: 'avenirWhite' }}
              border="1px"
              borderColor="avenirSuitabilityColor.600"
              onClick={resetReport}
            >
              Reset Report
            </Button> */}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Alerts;
