// Action Type
export const SET_FINANCIAL_DATA = 'SET_FINANCIAL_DATA';
export const SET_COMPOSITION = 'SET_COMPOSITION';
export const SET_PERSONALISED_PORTFOLIO_DATA = 'SET_PERSONALISED_PORTFOLIO_DATA';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_SUITABILITY_PROGRESS = 'SET_SUITABILITY_PROGRESS';
export const SET_AI_GENERATING = 'SET_AI_GENERATING'
export const SET_USER_GENERAL_INFO = 'SET_USER_GENERAL_INFO'
export const SET_CURRENT_REPORT_UID = 'SET_CURRENT_REPORT_UID'
export const SET_CLIENT_USER_LIST = 'SET_CLIENT_USER_LIST'

// Action Creator
export const setFinancialData = (data) => ({
  type: SET_FINANCIAL_DATA,
  payload: data,
});

export const setComposition = (composition) => ({
  type: SET_COMPOSITION,
  payload: composition,
});
export const setPersonalisedPortfolioData = (data) => ({
  type: SET_PERSONALISED_PORTFOLIO_DATA,
  payload: data,
});
export const setCurrentStep = (step) => ({
  type: SET_CURRENT_STEP,
  payload: step,
});
export const setSuitabilityProgress = (data) => ({
  type: SET_SUITABILITY_PROGRESS,
  payload: data,
});
export const setAiAnswerGenerating = (data) => ({
    type: SET_AI_GENERATING,
    payload: data
});
export const setUserGeneralInfo =(data) =>({
  type: SET_USER_GENERAL_INFO,
  payload: data,
})
export const setCurrentReportUid = (data) => ({
  type: SET_CURRENT_REPORT_UID,
  payload: data,
})
export const setUserClientList = (data) => ({
  type: SET_CLIENT_USER_LIST,
  payload: data,
})