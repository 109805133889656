import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Flex, Text, Box } from '@chakra-ui/react';

const V1FreeTrailCard = () => {
  const userInfo = useSelector((state) => state.usrinf);
  const navigate = useNavigate();
  const navigateTosubscribe = () => {
    navigate('/subscribe');
  };
  const navigateToSupport = () => {
    // Use this to open the URL in a new tab
    window.open(
      'https://calendly.com/avenir-andrea-macdonald/avenir-support',
      '_blank',
    );
  };
  return (
    <Box w="100%">
      {userInfo.plan.planName === 'Trial plan' ? (
        <Flex
          w="100%"
          h="160px"
          justify="center"
          direction="column"
          align="center"
          bgGradient="linear(135deg, #868CFF 0%, #4E13C3 100%)"
          position="relative"
          borderRadius="30px"
          border="2px solid transparent"
          sx={{
            background: `linear-gradient(white, white) padding-box, 
                     linear-gradient(89.86deg, #E0D7FF -1.63%, #E9CFFF 15.22%, #F2C3FF 35.53%, #F5B0D8 59.92%, #FFB3B3 76.84%) border-box`,
          }}
        >
          {userInfo.plan.trialRemainingDays > 0 ? null : (
            <Text
              color="avenirSuitabilityColor.600"
              fontFamily="DM Sans"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="24px"
              letterSpacing="-0.28px"
              mb="11px"
            >
              Your
            </Text>
          )}
          <Text
            fontFamily="DM Sans"
            fontSize="24px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="28px"
            letterSpacing="-0.28px"
            sx={{
              background: `conic-gradient(from 15.49deg at 69.3% 67.69%, 
          #A33E75 -0.56deg, 
          #FA6B25 36deg, 
          #4E13C3 324deg, 
          #A33E75 359.44deg, 
          #FA6B25 396deg)`,
              backgroundClip: 'text', // Clips background to text
              textFillColor: 'transparent', // Makes the text transparent so the gradient is visible
              WebkitBackgroundClip: 'text', // For WebKit browsers
              WebkitTextFillColor: 'transparent', // For WebKit browsers
            }}
          >
            {userInfo.plan.trialRemainingDays > 0
              ? userInfo.plan.trialRemainingDays
              : 'Free trial expired'}
          </Text>
          {userInfo.plan.trialRemainingDays > 0 ? (
            <Text
              color="avenirSuitabilityColor.600"
              fontFamily="DM Sans"
              fontSize="14px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="24px"
              letterSpacing="-0.28px"
              mb="11px"
            >
              days left on your free trial
            </Text>
          ) : null}
          <Button
            color="white"
            h="30px"
            fontFamily="DM Sans"
            fontSize="10px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="20px"
            letterSpacing="-0.2px"
            onClick={() => navigateTosubscribe()}
            border="2px solid transparent"
            borderRadius="30px"
            p="5px 8px"
            sx={{
              textStyle: 'dmsansNormal',
              fontsize: '10px',
              fontWeight: '700',
              background: `conic-gradient(from 15.49deg at 69.3% 67.69%, 
                        #A33E75 -0.56deg, 
                        #FA6B25 36deg, 
                        #4E13C3 324deg, 
                        #A33E75 359.44deg, 
                        #FA6B25 396deg)`,
              _hover: {
                background: `conic-gradient(from 15.49deg at 69.3% 67.69%, 
                  #A33E75 -0.56deg, 
                  #FA6B25 36deg, 
                  #4E13C3 324deg, 
                  #A33E75 359.44deg, 
                  #FA6B25 396deg)`,
              },
              backgroundColor: 'transparent', // Ensure no conflicting background
            }}
          >
            {userInfo.plan.trialRemainingDays > 0
              ? 'Upgrade now to benefit from discount '
              : 'Upgrade to create new reports'}
          </Button>
        </Flex>
      ) : null}
      {userInfo.plan.planName === 'Standard plan' ? (
        <Flex
          w="100%"
          h="160px"
          justify="center"
          direction="column"
          align="center"
          bgGradient="linear(135deg, #868CFF 0%, #4E13C3 100%)"
          position="relative"
          borderRadius="30px"
          border="2px solid transparent"
          sx={{
            background: `linear-gradient(white, white) padding-box, 
                     linear-gradient(89.86deg, #E0D7FF -1.63%, #E9CFFF 15.22%, #F2C3FF 35.53%, #F5B0D8 59.92%, #FFB3B3 76.84%) border-box`,
          }}
        >
          <Text
            color="avenirSuitabilityColor.600"
            fontFamily="DM Sans"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="-0.28px"
            mb="10px"
          >
            Your are on our
          </Text>
          <Text
            fontFamily="DM Sans"
            fontSize="24px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="28px"
            letterSpacing="-0.28px"
            sx={{
              background: `conic-gradient(from 15.49deg at 69.3% 67.69%, 
          #A33E75 -0.56deg, 
          #FA6B25 36deg, 
          #4E13C3 324deg, 
          #A33E75 359.44deg, 
          #FA6B25 396deg)`,
              backgroundClip: 'text', // Clips background to text
              textFillColor: 'transparent', // Makes the text transparent so the gradient is visible
              WebkitBackgroundClip: 'text', // For WebKit browsers
              WebkitTextFillColor: 'transparent', // For WebKit browsers
            }}
            mb="15px"
          >
            Standard plan
          </Text>
          <Button
            color="white"
            h="30px"
            fontFamily="DM Sans"
            fontSize="10px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="20px"
            letterSpacing="-0.2px"
            onClick={() => navigateToSupport()}
            border="2px solid transparent"
            borderRadius="30px"
            p="5px 8px"
            bg="white"
            sx={{
              background: `linear-gradient(white, white) padding-box, 
                       linear-gradient(89.86deg, #E0D7FF -1.63%, #E9CFFF 15.22%, #F2C3FF 35.53%, #F5B0D8 59.92%, #FFB3B3 76.84%) border-box`,
              _hover: {
                opacity: 0.9, // Add any hover effect here (like reducing opacity)
              },
            }}
          >
            <Text
              sx={{
                background: `conic-gradient(from 15.49deg at 69.3% 67.69%, 
            #A33E75 -0.56deg, 
            #FA6B25 36deg, 
            #4E13C3 324deg, 
            #A33E75 359.44deg, 
            #FA6B25 396deg)`,
                backgroundClip: 'text', // Clips background to text
                textFillColor: 'transparent', // Makes the text transparent so the gradient is visible
                WebkitBackgroundClip: 'text', // For WebKit browsers
                WebkitTextFillColor: 'transparent', // For WebKit browsers
              }}
            >
              Need anything? Speak to us
            </Text>
          </Button>
        </Flex>
      ) : null}
      {userInfo.plan.planName === 'Restricted plan' ? (
        <Flex
          w="100%"
          h="160px"
          justify="center"
          direction="column"
          align="center"
          bgGradient="linear(135deg, #868CFF 0%, #4E13C3 100%)"
          position="relative"
          borderRadius="30px"
          border="2px solid transparent"
          sx={{
            background: `linear-gradient(white, white) padding-box, 
                     linear-gradient(89.86deg, #E0D7FF -1.63%, #E9CFFF 15.22%, #F2C3FF 35.53%, #F5B0D8 59.92%, #FFB3B3 76.84%) border-box`,
          }}
        >
          <Text
            color="avenirSuitabilityColor.600"
            fontFamily="DM Sans"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="-0.28px"
            mb="10px"
          >
            You cancelled your
          </Text>
          <Text
            fontFamily="DM Sans"
            fontSize="24px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="28px"
            letterSpacing="-0.28px"
            sx={{
              background: `conic-gradient(from 15.49deg at 69.3% 67.69%, 
          #A33E75 -0.56deg, 
          #FA6B25 36deg, 
          #4E13C3 324deg, 
          #A33E75 359.44deg, 
          #FA6B25 396deg)`,
              backgroundClip: 'text', // Clips background to text
              textFillColor: 'transparent', // Makes the text transparent so the gradient is visible
              WebkitBackgroundClip: 'text', // For WebKit browsers
              WebkitTextFillColor: 'transparent', // For WebKit browsers
            }}
            mb="15px"
          >
            Standard plan
          </Text>
          <Button
            color="white"
            h="30px"
            fontFamily="DM Sans"
            fontSize="10px"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="20px"
            letterSpacing="-0.2px"
            onClick={() => navigateTosubscribe()}
            border="2px solid transparent"
            borderRadius="30px"
            p="5px 8px"
            sx={{
              textStyle: 'dmsansNormal',
              fontsize: '10px',
              fontWeight: '700',
              background: `conic-gradient(from 15.49deg at 69.3% 67.69%, 
                        #A33E75 -0.56deg, 
                        #FA6B25 36deg, 
                        #4E13C3 324deg, 
                        #A33E75 359.44deg, 
                        #FA6B25 396deg)`,
              backgroundColor: 'transparent', // Ensure no conflicting background
              _hover: {
                background: `conic-gradient(from 15.49deg at 69.3% 67.69%, 
                  #A33E75 -0.56deg, 
                  #FA6B25 36deg, 
                  #4E13C3 324deg, 
                  #A33E75 359.44deg, 
                  #FA6B25 396deg)`,
              },
            }}
          >
            Upgrade to create new reports
          </Button>
        </Flex>
      ) : null}
    </Box>
  );
};

export default V1FreeTrailCard;
