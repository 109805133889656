/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Text,
  Flex,
  ModalFooter,
} from '@chakra-ui/react';

const SessionTimeout = () => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const navigate = useNavigate();
  const timeoutRef = useRef(null); // Use useRef to store the timeout reference

  const startTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      setIsSessionExpired(true);
    }, 15 * 60 * 1000); // 12 seconds for testing
  };

  const resetTimeout = () => {
    if (!isSessionExpired) {
      // Prevent resetting if session has already expired
      clearTimeout(timeoutRef.current);
      startTimeout();
    }
  };

  useEffect(() => {
    startTimeout(); // Start the timeout on mount

    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);

    return () => {
      clearTimeout(timeoutRef.current);
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
    };
  }, [isSessionExpired]);

  const handleContinue = () => {
    setIsSessionExpired(false);
    navigate('/'); // Redirect to the dashboard
    resetTimeout(); // Reset the timeout for the next session
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Modal
      isOpen={isSessionExpired}
      onClose={handleContinue}
      closeOnOverlayClick={false} // Prevent closing by clicking outside the modal
      closeOnEsc={false} // Prevent closing by pressing the Escape key
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex w="100%" justifyContent="center">
            <Text
              sx={{ textStyle: 'dmsansNormal' }}
              fontSize="24px"
              fontWeight="700"
              color="#1B2559"
              mt="20px"
            >
              Session Expired
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Text
            sx={{ textStyle: 'dmsansNormal' }}
            fontSize="16px"
            fontWeight="400"
            color="#1B2559"
            textAlign="center"
          >
            {' '}
            Your session has expired due to inactivity.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            sx={{ textStyle: 'dmsansText' }}
            fontSize="14px"
            width="140px"
            h="40px"
            border="1px solid"
            borderColor="avenirSuitabilityColor.600"
            color="white"
            bg="avenirSuitabilityColor.600"
            _hover={{ bg: 'avenirSuitabilityColor.600' }}
            onClick={handleContinue}
          >
            Go to Dashboard
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SessionTimeout;
