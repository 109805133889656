const FE_PROXY_LINKING_BE_PRE_PROD = "https://suitability.web.app/"
const LOCAL_SERVER = "" // In dev, we use axios and a proxy to manage the connexion to the url. This is manatained in package.json. 

// TODO : Change this is you want to move to dev/prod environment 
const ENVIRONMENT = "prod"

export const SERVER_URL = ENVIRONMENT === "prod" ? FE_PROXY_LINKING_BE_PRE_PROD : LOCAL_SERVER 

// Liciense keys
export const SYNCFUSION_LICIENSE_KEY = "ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5VdEJiWX1ec3FVQmNU"

// Templete Name
export const SUITABILITY_TEMPLATE_NAME = "pension-switch"

// URL for backend calls 
export const API_PORTFOLIO_URL = SERVER_URL + "api/portfolio/"
export const API_INITIATE_SUITABILITY_REPORT = SERVER_URL + "api/report/initiate/"
export const API_SECTIONS_URL_BASE = SERVER_URL + 'api/report/sections/'
export const API_SUITABILITY_PROGRESS = SERVER_URL + 'api/progress/'
export const API_SUBSECTION_QUESTION = SERVER_URL + 'api/question/'
export const API_SUITABILITY_SERVER_ANSWER = SERVER_URL + 'api/serverAnswer/'
export const API_SUITABILITY_SUBSECTION_USER_ANSWER = SERVER_URL + 'api/userAnswer/'
export const API_SUITABILITY_HEADERS_FOOTER = SERVER_URL + 'api/hf/'
export const API_SUITABILITY_RESET_REPORT = SERVER_URL + 'api/reset/'
export const API_SUITABILITY_ISIN_SEARCH = SERVER_URL + 'api/searchfund/'
export const API_SUITABILITY_UPLOAD_REPORT = SERVER_URL + 'api/uploadpdf/'
export const API_SUITABILITY_UPLOAD_STATE = SERVER_URL + 'api/storestate/'
export const API_SUITABILITY_CREATE_CONTEXT = SERVER_URL + 'api/context/'
export const API_SUITABILITY_GET_CONTEXT = SERVER_URL   + 'api/storestate/'
export const API_SUITABILITY_UPLOAD_TEXT_REPORT = SERVER_URL+ 'api/uploadtext/'
export const API_SUITABILITY_UPLOAD_PDF_SECTION_DETAILS = SERVER_URL + 'api/uploadpdf/'
export const API_SUITABILITY_REMOVE_PDF_SECTION_FILE = SERVER_URL + 'api/removepdf/'
export const API_SUITABILITY_GET_CLIENTS_DETAILS = SERVER_URL + 'api/user/generalInfo'
export const API_SUITABILITY_GET_USER_FOOTER_DETAILS = SERVER_URL + 'api/user/footer'
export const API_SUIRABILITY_POST_USER_FOOTER_DETAILS = SERVER_URL + 'api/user/footer'
export const API_SUITABILITY_POST_USER_HEADER_DETAILS = SERVER_URL + 'api/user/header'
export const API_SUITABILITY_GET_USER_HEADER_DETAILS = SERVER_URL + 'api/user/header'
export const API_SUITABILITY_CREATE_USER = SERVER_URL + 'api/user/create/'
export const API_SUTIABILITY_GET_REPORT_LIST = SERVER_URL + 'api/report/list'
export const API_SUITABILITY_GET_CLIENTS_LIST = SERVER_URL + 'api/client/list'
export const API_SUITABILITY_CREATE_CLIENT = SERVER_URL + 'api/client/create'
export const API_SUITABILITY_STRIPE_CHECKOUT_LINK = SERVER_URL + 'api/stripe/checkoutLink'
export const API_SUITABILITY_STRIPE_PROTAL_LINK = SERVER_URL +'api/stripe/portalLink'
export const API_SUITABILITY_SFDT_HEADER_DETAILS = SERVER_URL + 'api/sfdt/header'
export const API_SUITABILITY_FUND_ONGOING_CHARGE = SERVER_URL + 'api/fund/ongoingCharge'
export const API_SUITABILITY_REPORT_EXPORT_DOC = SERVER_URL + 'api/export/docx'
export const API_SUITABILITY_REPORT_EXPORT_PDF = SERVER_URL + 'api/export/pdf'
export const API_SUITBILITY_REPORT_GET_USER_COLOR = SERVER_URL + 'api/user/color'
export const API_SUITABILITY_REPORT_POST_USER_COLOR = SERVER_URL + 'api/user/color'
export const API_SUITABLILITY_REPORT_USER_CAN_CREATE_REPORT = SERVER_URL + 'api/user/canCreateReports'