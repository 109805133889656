import React, { useState, useRef, useEffect } from 'react';
import { Box, Flex, Text, Textarea, Alert, AlertIcon } from '@chakra-ui/react';

import SkipValidateAnswer from './SkipValidateAnswer';

export default function Default({ question, userAnswered }) {
  const [text, setText] = useState(
    question.answers[0].AIAnswer ? question.answers[0].AIAnswer : '',
  );
  const textareaRef = useRef(null);
  const [showErrorState, setShowErrorState] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const handleInputChange = (e) => {
    setShowErrorState(false)
    setText(e.target.value);
    setTextAreaHeight()
  };
  const setTextAreaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset height to auto to recalculate height
      textarea.style.height = 'auto';
      // Set the height to the scrollHeight with constraints
      const newHeight = Math.min(textarea.scrollHeight, window.innerHeight * 0.50);
      textarea.style.height = `${newHeight}px`;
    }
  }
  const vallidateAnswer = () => {
    let hasError = false;
    if(!text.length) {
      hasError = true;
      setShowErrorState(true)
      setErrorMessage(' Please answer the required fields')
    }
    const regex_t = new RegExp(question.answers[0].regex);
    if(!regex_t.test(text)){
      hasError = true;
      setShowErrorState(true)
      setErrorMessage('Check format of your answer')
    }
    if (!hasError) {
      let userans = {
        default: text,
      };
      userAnswered(userans);
    }
  };
  const skipQuestion = () => {
    console.log('skipQuestion');
  };
  useEffect(() =>{
    setTextAreaHeight()
  }, [])
  return (
    <Flex
      h="100%"
      overflow="hidden"
      flexDirection="column"
      justifyContent="space-between"
      w="100%"
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Text sx={{ textStyle: 'manropeHeading' }} fontSize="18px">
            {question.text}
          </Text>
        </Flex>
        <Text
          sx={{ textStyle: 'manropeHeading' }}
          fontSize="14px"
          color="avenirGrey.400"
          lineHeight="16px"
          fontWeight="400"
          mt='8px'
        >
          {question.subText}
        </Text>
        <Flex justifyContent="space-between" alignItems="end" mt="24px">
          <Textarea
            id="cause-input"
            ref={textareaRef}
            value={text}
            placeholder={question.answers[0].placeholder}
            size="lg"
            borderRadius="20px"
            w="98%"
            resize="none"
            overflow="auto"
            _placeholder={{
              fontSize: 'md',
              color: 'gray.400',
            }}
            pt="10px"
            pb="10px"
            ml='3px'
            onChange={handleInputChange}
            borderColor={showErrorState ? '#E4103F !important' : '#E6E6E6 !important' }
            border="1px"
          />
        </Flex>
      </Box>
      <Flex flexDirection="row-reverse">
        {showErrorState ? (
          <Alert
            status="error"
            bg="#FED7D7"
            borderRadius="10px"
            sx={{ textStyle: 'manropeHeading' }}
            fontWeight="500"
          >
            <AlertIcon color="#E53E3E" />
            {errorMessage}
          </Alert>
        ) : (
          <SkipValidateAnswer
            skipQuestion={skipQuestion}
            vallidateAnswer={vallidateAnswer}
          />
        )}
      </Flex>
    </Flex>
  );
}
