import {  Flex } from '@chakra-ui/react';
import V1Settings from '../components/V1Settings'
import V1SideNav from '../components/V1SideNav'
const SuitabilityAccountSettings = () => {
    return (
      <Flex>
        <V1SideNav></V1SideNav>
        <V1Settings></V1Settings>
      </Flex>
    );
  };
  
  export default SuitabilityAccountSettings;