import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
//chakra components
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
  Flex,
  Text
} from '@chakra-ui/react';
//api
import { InitiateReportAPI } from 'services/apiServices';
//components
import ReportQuestion from './ReportQuestion';
import Dashboard from './Dashboard';
//redux actions
import { setCurrentReportUid } from '../../../reducers/actions';

const ReportVOne = ({newReportDetails}) => {
  const dispatch = useDispatch();
  const [currentSection, setCurrentSection] = useState(0);
  const [showCreateingReport, setShowCreateingReport] = useState(false);
  const sectinoChange = (value) => {
    setCurrentSection(value);
  };
  const InitiateReport = async () => {
    setShowCreateingReport(true);
    const response = await InitiateReportAPI(newReportDetails);
    const res = {
      "reportUID": response.reportUID,
      "reportName": newReportDetails.reportName
    }
    dispatch(setCurrentReportUid(res))
    setShowCreateingReport(false);
    if (response.existingReport) {
      setCurrentSection(1);
    }
  };
  useEffect(() => {
    InitiateReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      {currentSection === 0 ? (
        <Dashboard sectinoChange={sectinoChange} newReportDetails={newReportDetails}></Dashboard>
      ) : null}
      {currentSection === 1 ? <ReportQuestion></ReportQuestion> : null}
      <Modal isOpen={showCreateingReport}>
        <ModalOverlay />
        <ModalContent style={{ marginTop: '35vh' }}>
          <ModalBody>
            <Flex
              h="17vh"
              borderRadius="16px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Spinner
                thickness="8px"
                speed="0.65s"
                emptyColor="gray.200"
                color="avenirSuitabilityColor.800"
                width="55px" // Set custom width
                height="55px" // Set custom height
              />
              <Text
                sx={{ textStyle: 'manropeHeading' }}
                fontSize="20px"
                lineHeight="30px"
                fontWeight="500"
                letterSpacing="-0.4px"
                mt="20px"
                color="black"
              >
                Retrieving your report
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default ReportVOne;
