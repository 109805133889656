import React, {useState} from 'react';
import { Flex, Button } from '@chakra-ui/react';
//Icons
import { AiOutlineCheck } from 'react-icons/ai';

export default function Default({ vallidateAnswer, skipQuestion }) {
  const [isDisabled, setIsDisabled] = useState(false);
  // const skipQuestions = () => {
  //   skipQuestion();
  // };
  const vallidateAnswers = () => {
    setIsDisabled(true);
    vallidateAnswer();
    setTimeout(() => {
      setIsDisabled(false);
    }, 1000);
  };
  return (
    <Flex alignItems="center">
      {/* <Text
        onClick={skipQuestions}
        sx={{ textStyle: 'manropeHeading' }}
        color="avenirGrey.400"
        fontSize="14px"
        cursor="pointer"
      >
        Skip question
      </Text> */}
      <Button
        isDisabled={isDisabled}
        onClick={vallidateAnswers}
        bg="avenirSuitabilityColor.600"
        color="white"
        p="8px 18px"
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="14px"
        _hover={{ bg: "avenirSuitabilityColor.600" }}
        borderRadius='12px'
      >
        {<AiOutlineCheck style={{ fontSize: '24px', marginRight: '10px' }} />}
        OK
      </Button>
    </Flex>
  );
}
