/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  List,
  ListItem,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
  Text,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSuitabilityProgress,
  setAiAnswerGenerating,
} from 'reducers/actions';
//Component
import QuestionArea from 'views/suitabilityReportViews/components/QuestionArea';
// import SuitabilityCkEdiotr from 'views/suitabilityReportViews/components/SuitabilityCkEdiotr';
import SuitabilitySyncEditor from 'views/suitabilityReportViews/components/SuitabilitySyncEditor';
//API
import {
  getSectionInReportAPI,
  getSuitabilityProgressAPI,
  getServerAnswerAPI,
  getResetReport,
  firebaseLogout,
  getSyncEditorAnswer,
  getReportExportDoc,
} from 'services/apiServices';
//Icons
import { AvenirLogo } from 'components/icons/Icons';
import { AiOutlineMore } from 'react-icons/ai';
const reportVOne = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listRef = useRef();
  const currentReportUID = useSelector((state) => state.reportUID);
  const [editorData, setEditorData] = useState('');
  const [sectionDetails, setsectionDetails] = useState([]);
  const financialData = useSelector((state) => state.spr);
  const SuitabilityProgress = financialData;
  const [isAiGenerating, setisAiGenerating] = useState(false);
  const subSectionNumber = SuitabilityProgress.sectionNumber;
  const isReportFinished = SuitabilityProgress.reportFinished;
  const [isGeneratePdfOrDoc, setisGeneratePdfOrDoc] = useState('');
  const [storedUserData, setStoredUserData] = useState();
  const [triggerStoreSave, setTriggerStoreSave] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showCreateingReport, setShowCreateingReport] = useState(false);
  const [creatingReportMessage, setCreatingReportMessage] = useState(
    'Loading report',
  );
  const handleEditorDataChange = (newData) => {
    setEditorData(newData);
  };
  const updateTrigger = () => {
    let res = triggerStoreSave + 1;
    setTriggerStoreSave(res);
  };
  const generatePdfOrDoc = (value) => {
    setisGeneratePdfOrDoc(value);
  };
  const progressValue =
    (SuitabilityProgress.currentQuestionNumberInSection /
      SuitabilityProgress.questionsInsideSection) *
    100;
  //user answered the question
  const updateAfterUserAnswer = async () => {
    updateTrigger();
    await getSuitabilityProgress();
    getServerAnswer();
  };
  // appear modal
  const toggleList = () => {
    setIsOpen(!isOpen);
  };
  //Get Sections Api
  const getSectionInReport = async () => {
    const data = await getSectionInReportAPI(currentReportUID.reportUID);
    setsectionDetails(data);
    setTimeout(() => {
      getSuitabilityProgress();
    }, 100);
  };
  //Get Progress Api
  const getSuitabilityProgress = async () => {
    const data = await getSuitabilityProgressAPI(currentReportUID.reportUID);
    dispatch(setSuitabilityProgress(data));
  };
  //Get server AnswerApi
  const getServerAnswer = async () => {
    const data = await getServerAnswerAPI(currentReportUID.reportUID);
    if (data.serverAnswerText.length) {
      setisAiGenerating(true);
      setTimeout(() => {
        setisAiGenerating(false);
        setEditorData(data.serverAnswerText);
        dispatch(setAiAnswerGenerating({ aiAnswering: false }));
      }, 800);
    } else {
      setEditorData(data.serverAnswerText);
      dispatch(setAiAnswerGenerating({ aiAnswering: false }));
    }
  };
  const getSaveStoredEditorData = async () => {
    const data = await getSyncEditorAnswer(currentReportUID.reportUID);
    if (data.data.length) {
      setisAiGenerating(true);
      setTimeout(() => {
        setisAiGenerating(false);
        setStoredUserData(data.data);
      }, 800);
    } else {
      setEditorData(data.data);
    }
  };
  useEffect(() => {}, [SuitabilityProgress.sectionNumber]);
  useEffect(() => {
    getSectionInReport();
    getSaveStoredEditorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const restrartReport = async () => {
    setCreatingReportMessage('Removing current Data');
    setShowCreateingReport(true);
    try {
      await getResetReport(currentReportUID.reportUID);
      window.location.reload();
    } catch (error) {
      console.error('Error during report reset:', error);
      // Handle the error appropriately
    }
  };
  const exportReportDOC = async () => {
    try {
      const blob = await getReportExportDoc(currentReportUID.reportUID); // Get the Blob directly
      // Create a URL for the Blob and download it
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${currentReportUID.reportName}.docx`); // Set the file name and extension
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Revoke the object URL to free up memory
    } catch (error) {
      console.error('Error occurred while exporting report:', error);
    }
  };
  const logout = async () => {
    setCreatingReportMessage('Removing current Data');
    setShowCreateingReport(true);
    await firebaseLogout();
    setShowCreateingReport(false);
    window.location.reload();
  };
  const navigateToDashboard = () => {
    navigate('/suitability-report/my-reports');
    window.location.reload();
  };
  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <Box h="100vh" w="100vw">
      <Flex flexDirection="column" height="100vh" w="100vw" position="fixed">
        <Flex
          position="sticky"
          top="0"
          width="100%"
          height="10%"
          bg="white"
          zIndex="1000"
          p="16px 32px 4px 32px"
          borderBottom="1px solid #E8E8E8"
          justifyContent="space-between"
          alignItems="center"
        >
          <AvenirLogo h="46px" w="177px" mb="18px" />
          <Box>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="transparent"
                _hover={{ bg: 'transparent' }}
                _expanded={{ bg: 'transparent' }}
                sx={{ textStyle: 'manropeHeading' }}
                fontSize="18px"
                fontWeight="500"
                letterSpacing="-0.48px"
              >
                {currentReportUID.reportName}
              </MenuButton>
              <MenuList minW="150px" borderRadius="8px" boxShadow="md">
                <MenuItem
                  borderRadius="10px 10px 0 0 "
                  bg="white"
                  _hover={{ bg: 'avenirGrey.100' }}
                  fontFamily="DM Sans"
                  width="100%"
                  fontWeight="500"
                  justifyContent="flex-start"
                  onClick={restrartReport}
                >
                  Delete Report
                </MenuItem>
                <MenuItem
                  borderRadius="10px 10px 0 0 "
                  bg="white"
                  _hover={{ bg: 'avenirGrey.100' }}
                  fontFamily="DM Sans"
                  width="100%"
                  fontWeight="500"
                  justifyContent="flex-start"
                  onClick={() => exportReportDOC()}
                >
                  Export to Word
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
          <Box>
            <AiOutlineMore
              style={{ fontSize: '32px' }}
              cursor="pointer"
              onClick={toggleList}
            />
            {isOpen && (
              <Box
                ref={listRef}
                position="absolute"
                top="66px"
                right="30px"
                bg="white"
                borderRadius="12px"
              >
                <List
                  border="1px"
                  borderColor="avenirGrey.200"
                  borderRadius="8px"
                >
                  <ListItem borderRadius="12px">
                    <Button
                      borderRadius="0 0 0px 0px"
                      bg="white"
                      _hover={{ bg: 'avenirGrey.100' }}
                      fontFamily="DM Sans"
                      width="100%"
                      fontWeight="500"
                      justifyContent="flex-start"
                      onClick={() => navigateToDashboard()}
                    >
                      Back To Dashboard
                    </Button>
                  </ListItem>
                  <ListItem borderRadius="12px">
                    <Button
                      borderRadius="0 0 10px 10px"
                      bg="white"
                      _hover={{ bg: 'avenirGrey.100' }}
                      fontFamily="DM Sans"
                      width="100%"
                      fontWeight="500"
                      justifyContent="flex-start"
                      onClick={logout}
                    >
                      Logout
                    </Button>
                  </ListItem>
                </List>
              </Box>
            )}
          </Box>
        </Flex>
        <Flex bg="avenirGrey.100" h="90vh" w="100%">
          <Box w="30%" p="32px 8px 16px 16px">
            <QuestionArea
              sectionDetails={sectionDetails}
              currentSection={subSectionNumber}
              updateAfterUserAnswer={updateAfterUserAnswer}
              progressValue={progressValue}
              isAiGenerating={isAiGenerating}
              generatePdfOrDoc={generatePdfOrDoc}
            ></QuestionArea>
          </Box>
          <Box w="70%" p="32px 16px 16px 8px">
            <SuitabilitySyncEditor
              h="100%"
              data={editorData}
              storedUserData={storedUserData}
              onChange={handleEditorDataChange}
              isReportFinished={isReportFinished}
              isGeneratePdfOrDoc={isGeneratePdfOrDoc}
              triggerStoreSave={triggerStoreSave}
            />
          </Box>
        </Flex>
      </Flex>
      <Modal isOpen={showCreateingReport}>
        <ModalOverlay />
        <ModalContent style={{ marginTop: '35vh' }}>
          <ModalBody>
            <Flex
              h="17vh"
              borderRadius="16px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Spinner
                thickness="8px"
                speed="0.65s"
                emptyColor="gray.200"
                color="avenirSuitabilityColor.800"
                width="55px" // Set custom width
                height="55px" // Set custom height
              />
              <Text
                sx={{ textStyle: 'manropeHeading' }}
                fontSize="20px"
                lineHeight="30px"
                fontWeight="500"
                letterSpacing="-0.4px"
                mt="20px"
                color="black"
              >
                {creatingReportMessage}
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default reportVOne;
