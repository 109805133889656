import { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import V1SideNav from '../components/V1SideNav';
import ReportsOverview from '../components/ReportsOverview';
import V1NewReport from '../components/V1NewReport';
import CreateReport from './index'

const MyReports = () => {
  const [createNewReportState, setCreateNewReportState] = useState(0)
  const [newReportDetails, setNewReportDetails] = useState()
  const UpdateNewReportState = (val) => {
    setCreateNewReportState(val)
  }
  const AsssignNewReportDetails = (val) => {
    setNewReportDetails(val)
  }
  return (
    <Flex>
      {createNewReportState === 1 ? (
        <Flex  h="100vh" overflow="auto">
          <V1SideNav></V1SideNav>
          <V1NewReport AsssignNewReportDetails={AsssignNewReportDetails} UpdateNewReportState={UpdateNewReportState}></V1NewReport>
        </Flex> ) : null }
      {createNewReportState === 0 ? (
        <Flex>
          <V1SideNav></V1SideNav>
          <ReportsOverview AsssignNewReportDetails={AsssignNewReportDetails} UpdateNewReportState={UpdateNewReportState}></ReportsOverview>
        </Flex>
      ) : null}
      {createNewReportState === 2 ? (
        <CreateReport newReportDetails={newReportDetails}></CreateReport>
      ) : null}
    </Flex>
  );
};

export default MyReports;
