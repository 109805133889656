import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Text,
  List,
  ListItem,
  ListIcon,
} from '@chakra-ui/react';
import { AvenirLogo } from 'components/icons/Icons';
import { MdFiberManualRecord } from 'react-icons/md';
//api services
import {getStripeCheckoutLink} from '../../../services/apiServices'

function V1Payments() {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState({
    monthly: true,
    yearly: false,
  });
  const navigateToDashboard = () => {
    navigate('/suitability-report/my-reports', { replace: true });
  }
  const navigateToPayment = async () => {
    let planType = activeButton.monthly ? 'monthly' : 'annual'
    const res = await getStripeCheckoutLink(planType)
    window.open(res.url, '_blank');
  }
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      w="100vw"
      h="100vh"
      backgroundColor="#FCFCFD"
    >
      <AvenirLogo h="46px" w="177px" mt="10px" />
      <Text
        sx={{ textStyle: 'dmsansHeading' }}
        fontSize="32px"
        mb="15px"
        mt="20px"
      >
       Your free trial is limited to 14 days.
      </Text>
      <Text
        sx={{ textStyle: 'dmsansNormal' }}
        fontSize="18px"
        color="#1B2559"
        mb="25px"
      >
        Subscribe to a plan to secure your early bird discount now
      </Text>
      <Flex mb="20px" zIndex="2" bg="brand.900" borderRadius="60px" p="6px">
        <Button
          variant="no-hover"
          w="135px"
          h="40px"
          fontSize="xs"
          color={activeButton.monthly ? 'brand.500' : 'white'}
          bg={activeButton.monthly ? 'white' : 'transparent'}
          onClick={() => setActiveButton({ monthly: true, yearly: false })}
          borderRadius="60px"
        >
          Monthly
        </Button>
        <Button
          variant="no-hover"
          w="135px"
          h="40px"
          fontSize="xs"
          color={activeButton.yearly ? 'brand.500' : 'white'}
          bg={activeButton.yearly ? 'white' : 'transparent'}
          onClick={() => setActiveButton({ monthly: false, yearly: true })}
          borderRadius="60px"
        >
          Annually
        </Button>
      </Flex>
      <Flex
        w="377px"
        borderRadius="30px"
        backgroundColor="white"
        boxShadow="0px 17px 40px 4px rgba(112, 144, 176, 0.08)"
        flexDirection="column"
        padding="17px 15px 17px 24px"
      >
        <Text
          sx={{ textStyle: 'dmsansNormal' }}
          color="#FFB547"
          alignSelf="end"
          backgroundColor="#FFF6DA"
          padding="2px 7px"
          borderRadius="16px"
          letterSpacing="-0.28px"
          mb="4px"
        >
          Limited Time Offer
        </Text>
        <Text sx={{ textStyle: 'dmsansHeading' }} fontSize="28px" mb="10px">
          {' '}
          Standard
        </Text>
        <Button
          w="320px"
          color="avenirSuitabilityColor.600"
          borderRadius="16px"
          backgroundColor="avenirSuitabilityColor.50"
          height="48px"
          _hover={{backgroundColor:"avenirSuitabilityColor.50"}}
          onClick={() => navigateToPayment()}
        >
          Select {activeButton.monthly ? 'monthly' : 'annual'} plan
        </Button>
        <Box>
          <Text
            sx={{ textStyle: 'dmsansHeading' }}
            fontSize="32px"
            textDecoration="line-through"
            mt="10px"
          >
            <Text as="span">£{activeButton.monthly ? 250 : 3000}</Text>
            <Text as="span" color="secondaryGray.600">
              /{activeButton.monthly ? 'month' : 'year'}
            </Text>
          </Text>
          <Text sx={{ textStyle: 'dmsansHeading' }} fontSize="32px">
            <Text as="span">£{activeButton.monthly ? 99 : 999}</Text>
            <Text as="span" color="secondaryGray.600">
              /{activeButton.monthly ? 'month' : 'year'}*
            </Text>
          </Text>
        </Box>
        <Text
          mt="10px"
          sx={{ textStyle: 'dmsansNormal' }}
          fontSize="16px"
          color="secondaryGray.600"
          lineHeight="26px"
          fontWeight="500"
          letterSpacing="-0.32px"
        >
          {!activeButton.monthly ? '(Price per user per year)' : '(Price per user per month)'} <br /> *Offer valid for the first year only
        </Text>
        <Box mt="10px" mb="10px">
          <List spacing={3} sx={{ textStyle: 'dmsansNormal' }} fontSize="16px">
            <ListItem display="flex" alignItems="center">
              <ListIcon as={MdFiberManualRecord} color="#2B3674"  boxSize="8px"/>
              Accurate data extraction
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={MdFiberManualRecord} color="#2B3674" boxSize="8px"/>
              Systematic data validation
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={MdFiberManualRecord} color="#2B3674" boxSize="8px"/>
              Exports in PDF and Word
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={MdFiberManualRecord} color="#2B3674" boxSize="8px"/>
              24/7 Customer support
            </ListItem>
          </List>
        </Box>
      </Flex>
      <Button
        w="377px"
        mt="15px"
        borderRadius="12px"
        p="8px 18px"
        marginRight="10px"
        bg="avenirWhite"
        color="avenirSuitabilityColor.600"
        _hover={{ bg: 'avenirWhite' }}
        border="1px"
        borderColor="avenirSuitabilityColor.600"
        sx={{ textStyle: 'dmsansNormal' }}
        fontSize="14px"
        fontWeight="700"
        onClick={() => navigateToDashboard()}
      >
       Back to dashboard
      </Button>
    </Flex>
  );
}

export default V1Payments;
