/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
// Custom components
import Card from 'components/card/Card';
import React, { useEffect, useState } from 'react';
import V1UploadPdf from './V1UploadPdf';
import { postCreateContext } from 'services/apiServices';
const UploadComponent = ({ sectionUploadChange, uploadPdfsectiondetails }) => {
  const currentReportUID = useSelector((state) => state.reportUID);
  const [activeBullets, setActiveBullets] = useState({
    product: true,
    media: false,
    pricing: false,
  });
  const [activeTab, setActiveTab] = useState(0); // Add state for active tab
  const [uploadedRequiredfields, setUploadedRequiredfields] = useState(false);
  const currentUpload = React.useRef();
  const recommend = React.useRef();
  const middleSection = React.useRef();
  const [showCreateingReport, setShowCreateingReport] = useState(false);
  const [showLoadingInNext, setShowLoadingInNext] = useState(false);
  const [middleIndexCount, setMiddleIndexCount] = useState(1);
  const [middleIndexArray, setMiddleIndexArray] = useState([]);
  const [currentMiddleIndex, setCurrentMiddleIndex] = useState(0);
  const [consolidatedDocuments, setConsolidatedDocuments] = useState({});
  const uploadSectionDetails = uploadPdfsectiondetails
    ? uploadPdfsectiondetails
    : [];
  const lengthOfArray = uploadSectionDetails.length - 1;
  const updatePdfState = (val) => {
    if (val) {
      setUploadedRequiredfields(true);
    }
  };
  const setLoadingInNextButton = (val) => {
    setShowLoadingInNext(val);
  };
  const submitPdf = async () => {
    const res = {
      reportUID: currentReportUID.reportUID,
    };
    setShowCreateingReport(true);
    await postCreateContext(res);
    setShowCreateingReport(false);
    sectionUploadChange(1);
  };
  const handleTabChange = (index) => {
    if (uploadedRequiredfields) {
      setActiveTab(index);
      if (index === 0) {
        setActiveBullets({ product: true, media: false, pricing: false });
      } else if (index === 1) {
        setActiveBullets({ product: true, media: true, pricing: false });
      } else if (index === 2) {
        setActiveBullets({ product: true, media: true, pricing: true });
      }
    }
  };
  const changeNoOfSections = () => {
    if (middleIndexCount + 1 < uploadPdfsectiondetails.length - 1) {
      setMiddleIndexCount(middleIndexCount + 1);
      setTimeout(() => {
        setCurrentMiddleIndex(middleIndexCount);
      }, 300);
    }
  };
  const updateDocument = (documentKey, newValue) => {
    setConsolidatedDocuments((prevDocuments) => ({
      ...prevDocuments,
      [documentKey]: newValue,
    }));
  };
  const removeConsolidatedSectionName = (val) => {
    setConsolidatedDocuments((prevDocuments) => ({
      ...prevDocuments,
      [val]: '',
    }));
  }
  useEffect(() => {
    if (
      middleIndexCount > 0 &&
      middleIndexCount < uploadPdfsectiondetails.length - 1
    ) {
      // Slice the array from index 1 to the specified middleIndexCount
      const slicedArray = uploadPdfsectiondetails.slice(
        1,
        middleIndexCount + 1,
      );
      setMiddleIndexArray(slicedArray);
    }
  }, [middleIndexCount]);
  useEffect(() => {
    const middleElements = uploadPdfsectiondetails.slice(1, -1);
    const documents = {};
    // Iterate over each element in middleElements
    middleElements.forEach((element) => {
      // Iterate over each document in the documents array
      element.documents.forEach((document) => {
        // Add the document as a key in the documents object with an empty string as its value
        documents[document] = '';
      });
    });
    // Set the state with the new consolidated documents object
    setConsolidatedDocuments(documents);
  }, []);
  return (
    <Box w="100%" p="36px" h="100%" overflow="auto">
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        color="#707EAE"
        fontWeight="500"
        fontSize="14px"
        lineHeight="24px"
      >
        Dashboard / New Report
      </Text>
      <Text
        sx={{ textStyle: 'manropeHeading' }}
        fontSize="34px"
        color="#2B3674"
        mb="5px"
        lineHeight="42px"
      >
        Clients’ Information
      </Text>
      <Flex
        direction="column"
        minH="100vh"
        align="center"
        p="24px"
        position="relative"
      >
        <Box
          h="30vh"
          bgGradient="linear(to-b, brand.400, brand.600)"
          position="absolute"
          w="100%"
          borderRadius="20px"
        />

        <Tabs
          variant="unstyled"
          mt={{ base: '60px', md: '50px' }}
          zIndex="0"
          display="flex"
          flexDirection="column"
          index={activeTab} // Control active tab
          onChange={() => {}} // Prevent changing tab on header click
        >
          <TabList
            display="flex"
            alignItems="center"
            alignSelf="center"
            justifySelf="center"
          >
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={currentUpload}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.media ? 'white' : 'brand.400',
                  left: {
                    sm: '12px',
                    md: uploadSectionDetails[0].category.length * 4,
                  },
                  top: {
                    sm: activeBullets.product ? '6px' : '4px',
                    md: null,
                  },
                  position: 'absolute',
                  bottom: activeBullets.product ? '40px' : '38px',

                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.product ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.product ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.product ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  {uploadSectionDetails[0].category}
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={middleSection}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
                _before={{
                  content: "''",
                  width: { sm: '120px', md: '250px', lg: '300px' },
                  height: '3px',
                  bg: activeBullets.pricing ? 'white' : 'brand.400',
                  left: '90px',
                  top: '6px',
                  position: 'absolute',
                  bottom: activeBullets.media ? '40px' : '38px',

                  transition: 'all .3s ease',
                }}
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.media ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.media ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.media ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  {uploadSectionDetails[1].category}
                </Text>
              </Flex>
            </Tab>
            <Tab
              _focus={{ border: '0px', boxShadow: 'unset' }}
              ref={recommend}
              w={{ sm: '120px', md: '250px', lg: '300px' }}
            >
              <Flex
                direction="column"
                justify="center"
                align="center"
                position="relative"
              >
                <Box
                  zIndex="1"
                  border="2px solid"
                  borderColor={activeBullets.pricing ? 'white' : 'brand.400'}
                  bgGradient="linear(to-b, brand.400, brand.600)"
                  w="16px"
                  h="16px"
                  mb="8px"
                  borderRadius="50%"
                />
                <Text
                  color={activeBullets.pricing ? 'white' : 'gray.300'}
                  fontWeight={activeBullets.pricing ? 'bold' : 'normal'}
                  display={{ sm: 'none', md: 'block' }}
                >
                  {uploadSectionDetails[lengthOfArray].category}
                </Text>
              </Flex>
            </Tab>
          </TabList>
          <TabPanels mt="24px" maxW={{ md: '90%', lg: '100%' }} mx="auto">
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text sx={{ textStyle: 'manropeHeading' }} fontSize="24px">
                  {uploadSectionDetails[0].title}
                </Text>
                <Text
                  sx={{ textStyle: 'manropeHeading' }}
                  fontSize="14px"
                  color="avenirGrey.400"
                  lineHeight="16px"
                  fontWeight="400"
                  mb="24px"
                  mt="8px"
                >
                  {uploadSectionDetails[0].description}
                </Text>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
                  {uploadSectionDetails[0].documents.map((secName, index) => (
                    <V1UploadPdf
                      key={index}
                      secName={secName}
                      updatePdfState={updatePdfState}
                      setLoadingInNextButton={setLoadingInNextButton}
                    />
                  ))}
                </SimpleGrid>
                <Flex direction="column" w="100%">
                  <Flex justify="space-between" mt="24px">
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      ms="auto"
                      bg="avenirSuitabilityColor.600"
                      onClick={() => handleTabChange(1)} // Change tab on Next button click
                    >
                      {showLoadingInNext ? (
                        <Spinner
                          thickness="2px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="avenirSuitabilityColor.800"
                          width="15px" // Set custom width
                          height="15px" // Set custom height
                        />
                      ) : (
                        'Next'
                      )}
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                {middleIndexArray
                  .filter((_, index) => index === currentMiddleIndex)
                  .flatMap((section, sectionIndex) => (
                    <Box key={`section-${sectionIndex}`}>
                      <Text
                        sx={{ textStyle: 'manropeHeading' }}
                        fontSize="24px"
                      >
                        {section.title}
                      </Text>
                      <Text
                        sx={{ textStyle: 'manropeHeading' }}
                        fontSize="14px"
                        color="avenirGrey.400"
                        lineHeight="16px"
                        fontWeight="400"
                        mb="24px"
                        mt="8px"
                      >
                        {section.description}
                      </Text>
                      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
                        {section.documents.map((secName, docIndex) => (
                          <Box key={secName}>
                            <V1UploadPdf
                              secName={secName}
                              updatePdfState={updatePdfState}
                              setLoadingInNextButton={setLoadingInNextButton}
                              canUpdate={true}
                              updateDocument={updateDocument}
                              isUploadedDocument={
                                consolidatedDocuments[secName]
                              }
                              removeConsolidatedSectionName={removeConsolidatedSectionName}
                            />
                          </Box>
                        ))}
                      </SimpleGrid>
                    </Box>
                  ))}
                <Flex direction="column" w="100%">
                  <Flex justify="space-between" mt="24px">
                    <Button
                      variant="light"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      onClick={() =>
                        currentMiddleIndex === 0
                          ? handleTabChange(0)
                          : setCurrentMiddleIndex(currentMiddleIndex - 1)
                      }
                    >
                      Go Back
                    </Button>
                    <Flex>
                      <Button
                        borderRadius="16px"
                        w="max-content"
                        h="46px"
                        ms="auto"
                        bg="white"
                        color="avenirSuitabilityColor.600"
                        border="1px solid"
                        borderColor="avenirSuitabilityColor.600"
                        _hover={{ bg: 'white' }}
                        onClick={() => changeNoOfSections()}
                        mr="10px"
                      >
                        Add {uploadSectionDetails[1].category}
                      </Button>
                      <Button
                        variant="darkBrand"
                        fontSize="sm"
                        borderRadius="16px"
                        w={{ base: '128px', md: '148px' }}
                        h="46px"
                        bg="avenirSuitabilityColor.600"
                        onClick={() =>
                          currentMiddleIndex === middleIndexCount - 1
                            ? handleTabChange(2)
                            : setCurrentMiddleIndex(currentMiddleIndex + 1)
                        }
                      >
                        Next
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Card>
            </TabPanel>
            <TabPanel
              w={{ sm: '330px', md: '700px', lg: '850px' }}
              p="0px"
              mx="auto"
            >
              <Card p="30px">
                <Text sx={{ textStyle: 'manropeHeading' }} fontSize="24px">
                  {uploadSectionDetails[lengthOfArray].title}
                </Text>
                <Text
                  sx={{ textStyle: 'manropeHeading' }}
                  fontSize="14px"
                  color="avenirGrey.400"
                  lineHeight="16px"
                  fontWeight="400"
                  mb="24px"
                  mt="8px"
                >
                  {uploadSectionDetails[lengthOfArray].description}
                </Text>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
                  {uploadSectionDetails[lengthOfArray].documents.map(
                    (secName, index) => (
                      <V1UploadPdf
                        key={index}
                        secName={secName}
                        updatePdfState={updatePdfState}
                        setLoadingInNextButton={setLoadingInNextButton}
                      ></V1UploadPdf>
                    ),
                  )}
                </SimpleGrid>
                <Flex direction="column" w="100%">
                  <Flex justify="space-between" mt="24px">
                    <Button
                      variant="light"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      onClick={() => handleTabChange(1)} // Change tab on Prev button click
                    >
                      Go Back
                    </Button>
                    <Button
                      variant="darkBrand"
                      fontSize="sm"
                      borderRadius="16px"
                      w={{ base: '128px', md: '148px' }}
                      h="46px"
                      bg="avenirSuitabilityColor.600"
                      onClick={submitPdf}
                    >
                      Submit Documents
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <Modal isOpen={showCreateingReport}>
        <ModalOverlay />
        <ModalContent style={{ marginTop: '35vh' }}>
          <ModalBody>
            <Flex
              h="17vh"
              borderRadius="16px"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Spinner
                thickness="8px"
                speed="0.65s"
                emptyColor="gray.200"
                color="avenirSuitabilityColor.800"
                width="55px" // Set custom width
                height="55px" // Set custom height
              />
              <Text
                sx={{ textStyle: 'manropeHeading' }}
                fontSize="20px"
                lineHeight="30px"
                fontWeight="500"
                letterSpacing="-0.4px"
                mt="20px"
                color="black"
              >
                Extracting data from your files
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default UploadComponent;
