/*eslint-disable*/
import React from "react";
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");
  const handleDownload = () => {
    // Path to the PDF file
    const pdfUrl = `${process.env.PUBLIC_URL}/Avenir_Website_Privacy_Policy.pdf`; 
    // Create a new anchor element
    const link = document.createElement('a');
    // Set the href attribute to the PDF URL
    link.href = pdfUrl;
    // Set the download attribute with a filename
    link.download = 'AvenirPolicy.pdf'; // You can specify a default filename
    // Append the anchor to the body
    document.body.appendChild(link);
    // Programmatically trigger a click event on the anchor
    link.click();
    // Remove the anchor from the document
    document.body.removeChild(link);
  };
  return (
    <Flex
      w={{ base: "100%", xl: "1170px" }}
      maxW={{ base: "90%", xl: "1170px" }}
      zIndex='1.5'
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent='space-between'
      px={{ base: "0px", xl: "0px" }}
      pb='30px'
      mx='auto'>
      <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}>
        {" "}
        &copy; {1900 + new Date().getYear()}
        <Text as='span' fontWeight='500' ms='4px'>
          Avenir. All Rights Reserved.
        </Text>
      </Text>
      <List display='flex'>
        {/* <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={textColor}
            href='mailto:hello@simmmple.com'>
            Support
          </Link>
        </ListItem> */}
        {/* <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={textColor}
            href='https://www.simmmple.com/licenses'>
            License
          </Link>
        </ListItem> */}
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}>
          <Link
            fontWeight='500'
            color={textColor}
            onClick={handleDownload}>
            Privacy Policy
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
