import axios from 'axios';
import axiosRetry from 'axios-retry';

// Configure axiosRetry
axiosRetry(axios, {
    retries: 2, // Number of retries
    retryDelay: (retryCount) => {
        console.log(`Retry attempt: ${retryCount}`);
        return retryCount * 2000; // 2 seconds delay between retries
    },
    retryCondition: (error) => {
        return error.response && error.response.status >= 500 && error.response.status < 600; // Retry on all 5xx status codes
    },
});

// Add an interceptor to handle logging after retries
axios.interceptors.response.use(
    response => response,
    error => {
        const config = error.config;
        if (!config) return Promise.reject(error);

        // Access the retry count directly from the config object
        const retryCount = config['axios-retry'] ? config['axios-retry'].retryCount : 0;
        const retries = 2; // Number of retries configured in axiosRetry

        // If retries are exhausted
        if (retryCount >= retries) {
            console.log('All retries are exhausted.');
            window.location.replace('/error'); 
        }

        return Promise.reject(error);
    }
);

export default axios;
