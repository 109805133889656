import {
  Box,
  Button,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Image,
} from '@chakra-ui/react';
// Images
import pension_switch from '../../../assets/img/SuitabilityReport/pension_switch.png';
import pension_consolidation from '../../../assets/img/SuitabilityReport/consolidation.png';
import linkedin_banner from '../../../assets/img/SuitabilityReport/linkedIn_banner.jpeg';

const V1PreviewTemplate = ({ previewType, selectCard, handleClose }) => {
  const SectionDetails = [
    'Executive Summary',
    'Current Pension Details',
    'Clients’ Profile',
    'Recommendation Details',
  ];
  return (
    <Box>
      <Modal isOpen={true} onOverlayClick={handleClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent maxW={{ xl: '65vw', '2xl': '60vw' }} maxH="90vh">
          <ModalHeader p="24px">
            <Box>
              <Text
                sx={{ textStyle: 'dmsansText' }}
                fontSize="24px"
                fontWeight="700"
                mb="38px"
              >
                Template Preview
              </Text>
              <Text
                sx={{ textStyle: 'dmsansText' }}
                fontSize="48px"
                fontWeight="700"
              >
                {previewType === 'switch'
                  ? 'Pension Switch'
                  : 'Pension Consolidation'}
              </Text>
            </Box>
          </ModalHeader>
          <ModalBody
            display="flex"
            alignItems="normal"
            justifyContent="space-between"
            flexDirection="column"
            p="24px"
          >
            <Flex flexDirection="row">
              <Box
                borderRadius="8px"
                border="2px solid #F9F9F9"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                mr="20px"
              >
                <Image
                  src={previewType === 'switch' ? pension_switch : pension_consolidation}
                  borderRadius="14px"
                  height="auto" // Set height to auto
                  width="100%"  // Set width to 100% of its container
                  objectFit="contain" // Maintain aspect ratio within the container
                />
              </Box>
              <Flex flexDirection="column" justifyContent="space-between">
                <Box mt="8px">
                  <Text
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="18px"
                    mb="8px"
                    color="#1B2559"
                    fontWeight="700"
                  >
                    About this template
                  </Text>
                  <Text
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="16px"
                    mb="18px"
                    color="#1B2559"
                    w="100%"
                    fontWeight="400"
                    letterSpacing="-0.32px"
                    lineHeight="26px"
                  >
                    {previewType === 'switch'
                      ? 'Create a suitability letter for one to one pension switches. Provide your clients with clear visibility on your recommendations for better benefits, lower fees, or improved investment options for their pension.'
                      : 'Generate a suitability letter consolidating up to 10 pensions into one, giving your clients visibility to your recommendation for better benefits, lower fees or improved investment options for their pension.'}
                  </Text>
                  <Text
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="18px"
                    mb="20px"
                    color="#1B2559"
                    fontWeight="700"
                  >
                    Approved By
                  </Text>
                  <Image
                    src={linkedin_banner}
                    borderRadius="14px"
                    h="32px"
                    w="152px"
                    mb="20px"
                  />
                  <Text
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="18px"
                    mb="8px"
                    color="#1B2559"
                    fontWeight="700"
                  >
                    Average Time to Create
                  </Text>
                  <Text
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="16px"
                    mb="18px"
                    color="#1B2559"
                    w="400px"
                    fontWeight="400"
                    letterSpacing="-0.32px"
                    lineHeight="26px"
                  >
                    {previewType === 'switch' ? '15 - 20 Mins' : '35 - 40 Mins'}
                  </Text>
                  <Text
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="18px"
                    mb="8px"
                    color="#1B2559"
                    fontWeight="700"
                  >
                    Sections
                  </Text>
                  <Flex flexWrap="wrap" gap="16px">
                    {SectionDetails.map((section, index) => (
                      <Text
                        key={index}
                        sx={{ textStyle: 'dmsansText' }}
                        fontSize="14px"
                        fontWeight="500"
                        borderRadius="18px"
                        border="1px solid"
                        borderColor="avenirSuitabilityColor.600"
                        color="avenirSuitabilityColor.600"
                        p="8px 10px"
                        mb="4px" // Adding margin-bottom for spacing between items
                      >
                        {section}
                      </Text>
                    ))}
                  </Flex>
                </Box>
                <Flex justifyContent="end">
                  <Button
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="14px"
                    width="150px"
                    h="44px"
                    border="1px solid"
                    borderColor="avenirSuitabilityColor.600"
                    color="avenirSuitabilityColor.600"
                    bg="white"
                    mr="10px"
                    _hover={{ bg: 'white' }}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button
                    sx={{ textStyle: 'dmsansText' }}
                    fontSize="14px"
                    width="150px"
                    h="44px"
                    border="1px solid"
                    borderColor="avenirSuitabilityColor.600"
                    color="white"
                    bg="avenirSuitabilityColor.600"
                    _hover={{ bg: 'avenirSuitabilityColor.600' }}
                    onClick={() => selectCard(previewType)}
                  >
                    Select Template
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default V1PreviewTemplate;
