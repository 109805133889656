import { combineReducers } from 'redux';
import financialDataReducer from './myReducer';
import compositionReducer from './compositionReducer';
import personalisedPortReducer from './personalisedPortReducer';
import currentStepReducer from './currentStepReducer';
import {SuitabilityProgressReducer, AiAnswerReducer, UserGeneralInfo, CurrentReportUid, CLientUserList }from './SuitabilityProgressReducer'

const rootReducer = combineReducers({
  my: financialDataReducer,
  cr: compositionReducer,
  ppd: personalisedPortReducer,
  cs: currentStepReducer,
  spr: SuitabilityProgressReducer,
  ai: AiAnswerReducer,
  usrinf: UserGeneralInfo,
  reportUID: CurrentReportUid,
  clientList: CLientUserList
  // Add other reducers here when you have them
});

export default rootReducer;


